import React from 'react';

function App() {
  return <div className="container">
  <div>
      <div>
          <h1>Latch Here . Com</h1>
          
          <h2 className="sentence tag">Connects you
              <span className="topToBottom">to</span>
              <div className="slidingVertical">
                  <span className="tag">Attractions.</span>
                  <span className="tag">Restaurants</span>
                  <span className="tag"> Airports</span>
                  <span className="tag">Malls</span>
                  <span className="tag2">EVERYWHERE !</span>
              </div>
          </h2>
          <h4>
              <br></br><br></br><br></br>Web | iOS | Android<br></br> <br></br> <br></br> Thanks for stopping by we are busy building something fun. To explore/contribute please visit <a href="https://github.com/takrishna/latchherecloud"> github. </a>
          </h4>
      </div>
      <svg  className="pulse" viewBox="0 0 1024 1024" version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <circle id="Oval" cx="512" cy="512" r="512"></circle>
          <circle id="Oval" cx="512" cy="512" r="512"></circle>
          <circle id="Oval" cx="512" cy="512" r="512"></circle>
      </svg>
  </div>
</div>;
}

export default App;
